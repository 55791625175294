import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';
const { api } = apiConfig;

export const inVenueSlice = createSlice({
  name: 'invenue',
  initialState: {
    open: false,
    loggedIn: false,
    token: null,
  },
  reducers: {
    showInVenue: (state) => {
      state.open = true;
    },
    hideInVenue: (state) => {
      state.open = false;
    },
  },
});

export const inVenueApi = createApi({
  reducerPath: 'inVenueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
  }),
  endpoints: (builder) => ({
    validateParticipant: builder.query({
      query: ({ eventId, email_id, id, auth, sessionId }) => ({
        url: `content/events/${eventId}/venue/validate`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
          username: email_id,
          id,
          session: sessionId,
        },
      }),
    }),
    getParticipantBookingInfo: builder.query({
      query: ({ eventId, id, auth, sessionId }) => ({
        url: `content/events/${eventId}/venue/bookinginfo?member=${id}`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
          id,
          session: sessionId,
        },
      }),
    }),
    bookWS: builder.mutation({
      query: ({ eventId, workship_id, member, auth }) => ({
        url: `content/events/${eventId}/venue/workshops/${workship_id}/book`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
        },
        body: {
          member,
        },
      }),
    }),
    bookMS: builder.mutation({
      query: ({ eventId, mentorship_id, member, auth, booking_info = [] }) => ({
        url: `content/events/${eventId}/venue/mentorships/${mentorship_id}/book`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
        },
        body: {
          member,
          data: booking_info,
        },
      }),
    }),
    markAsAttended: builder.mutation({
      query: ({ eventId, mentorship_id, member, auth, booking_info = [] }) => ({
        url: `content/events/${eventId}/venue/markattendance`,
        method: 'POST',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
        },
        body: {
          member,
        },
      }),
    }),
  }),
});

export const { showInVenue, hideInVenue, loggingIn } = inVenueSlice.actions;
export const { useValidateParticipantQuery, useBookWSMutation, useBookMSMutation, useGetParticipantBookingInfoQuery, useMarkAsAttendedMutation } =
  inVenueApi;

// export default inVenueSlice.reducer;
