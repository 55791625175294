import React from 'react';
import './App.css';
import { StencilTenant, useTenantContext, useGetPageContentQuery, DialogVideo } from 'enterprise-stencil';
import { ClientUIContextProvider, SectionLoader } from '@stencil-core/ui';
import { useRoutes, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import { store, persistor } from './store';
import { setEventId } from './api/tenant';
import { useGetActiveEventsQuery } from './api/tenant';
import { setExistingUserData } from './api/validateUser';
import { showInVenue } from './api/invenue';
import Workshop from './Components/Workshop/Workshop';
import Gallery from './Components/Gallery/Gallery';
import Unsubscribe from './Components/Unsubscribe/Unsubscribe';
// import AgendaCustom from './Components/Agenda/AgendaCustom';
import VideoInFullPage from './Components/Video/VideoInFullPage';
// import CustomTest from './Components/CustomTest';
import { envConst } from './apiConfig';
import usePageTracking from './Utils/usePageTracking';
import ScrollToTop from './Utils/ScrollToTop';
import CustomHighlights from './Components/CustomHighlights/CustomHighlights';
import HeroBanner from './Components/HeroBanner/HeroBanner';
import Speakers from './Components/Speakers/Speakers';

// import Registration from './Components/Registration/Registration';
// import AdminPanel from './Components/InVenueBooking/AdminPanel/AdminPanel';
// import InVenueBooking from './Components/InVenueBooking/InVenueBooking';

// import NewRegister from './Components/NewRegister/NewRegister';
// import SignUpDialog from './Components/Registration/SignUpDialog';
// import { deepmerge } from '@mui/utils';
// import utilFunction from './Components/Shared/utilFunction';
// import Mentorships from './Components/Mentorships/Mentorship';

const customComponents = {
  // AgendaCustom: AgendaCustom, //name in stencil:name of the component in client repo
  Gallery: Gallery,
  CustomHighlights: CustomHighlights,
  HeroBanner: HeroBanner,
  Speakers: Speakers,
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Container = (props) => {
  const location = useLocation();
  const { tenantId } = useTenantContext();
  const dispatch = useDispatch();
  let query = useQuery();
  const preview = query.get('preview');
  const { data: fetchedData, isLoading } = useGetPageContentQuery(
    {
      tenantId,
      slug: location.pathname.slice(1) ? `/${location.pathname.slice(1)}` : '',
      previewToken: preview,
    },
    { skip: !tenantId }
  );

  const { data: eventsData, error: eventsError } = useGetActiveEventsQuery({ site_id: tenantId }, { skip: !tenantId });

  React.useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      dispatch(setEventId({ eventId: eventsData[0]._id }));
      localStorage.setItem('eventId', JSON.stringify(eventsData[0]._id));
    }
    dispatch(
      setExistingUserData({
        member: {},
        meta: {},
        status: [],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  // handling the google AX

  usePageTracking();
  const { layout = [] } = fetchedData ? fetchedData : {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const isTab = useMediaQuery(theme.breakpoints.between(600, 1025));
  // const isDesktop = useMediaQuery(theme.breakpoints.up(1025));
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{fetchedData ? fetchedData.name : 'Target Elevate'}</title>
        <meta name='description' content={fetchedData ? fetchedData.seo?.description : 'desc'} />
        <meta property='og:title' content={fetchedData ? fetchedData.seo?.title : ''} />
        <meta property='og:image' content={fetchedData ? fetchedData.seo?.image : ''} />
      </Helmet>
      <>
        <h1 className='visibility-hidden' aria-hidden='true'>
          {fetchedData && fetchedData.seo && fetchedData.seo.title ? fetchedData.seo.title : fetchedData && fetchedData.name ? fetchedData.name : ''}
        </h1>
        {isLoading ? (
          <Box sx={{ minHeight: '65vh', width: '100%' }} />
        ) : (
          // <div style={{ marginTop: isMobile ? '-10%' : '-4%' }}>
          <div style={{ marginTop: isMobile ? '5%' : '1%' }}>
            {layout.length > 0 ? (
              layout.map((section, sIndex) => {
                const sectionData = section.components[0];
                return sectionData ? (
                  <SectionLoader
                    key={sIndex}
                    sectionData={sectionData}
                    // previewWidth={1440}
                  />
                ) : (
                  <p></p>
                );
              })
            ) : (
              // <h1 style={{ minHeight: '100vh' }}>Page not found</h1>
              // If no matching routes found, take the user to home page
              <>{navigate('/')}</>
            )}
          </div>
        )}
        {eventsError && <p>There are no events registered yet</p>}
      </>
    </>
  );
};

const Main = () => {
  const [searchParams] = useSearchParams();
  const isMobileApp = searchParams.get('ismobileapp');
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isMobileApp === 'true') {
      dispatch(showInVenue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileApp]);

  return (
    <>
      <Routes />
    </>
  );
};

const Routes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { preloadedData, preloadedArticleData, isLoading } = props;
  let routes = useRoutes([
    {
      path: '*',
      element: <Container isLoading={isLoading} preloadedData={preloadedData} />,
    },
    // {
    //   path: '/register',
    //   element: <Registration />,
    // },
    {
      path: '/workshops',
      element: <Workshop></Workshop>,
    },
    // {
    //   path: '/gallery',
    //   element: <Gallery></Gallery>,
    // },
    {
      path: '/unsubscribe',
      element: <Unsubscribe />,
    },
    {
      path: '/videostory',
      element: <VideoInFullPage />,
    },
    // {
    //   path: '/newregister',
    //   element: <NewRegister />,
    // },
    // {
    //   path: '/registeronspot',
    //   element: <Registration />,
    // },
    // {
    //   path: '/mentorships',
    //   element: <Mentorships></Mentorships>,
    // },
    // {
    //   path: '/spot',
    //   element: <InVenueBooking />,
    // },
    // {
    //   path: '/adminpanel',
    //   element: <AdminPanel />,
    // },
  ]);
  return routes;
};

function App(props) {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [videoInfo, setVideoInfo] = React.useState(null);
  const [videoDialog, openVideoDialog] = React.useState(false);

  React.useEffect(() => {
    if (hash === '#highlights') {
      navigate('/highlights');
    }
  }, [hash, navigate]);

  const userActionsOnComponent = (type, settings = {}) => {
    if (type === 'video') {
      const { video_url = '', video_poster = '', video_title = '', caption_url = '', transcript_url = '' } = settings;
      let videoData = {
        title: video_title === undefined || video_title === '' ? 'SAMPLE TITLE' : video_title,
        poster: video_poster === undefined || video_poster === '' ? '' : video_poster,
        source: video_url ? [video_url] : [],
        tracks: caption_url ? [caption_url] : [],
        transcript: transcript_url ? [transcript_url] : [],
      };
      setVideoInfo({ ...videoData });
      openVideoDialog(true);
    } else {
      //navigate('/register');
    }
  };

  return (
    <div className='App'>
      <StencilTenant tenantId={envConst.tenantId}>
        <ClientUIContextProvider customComponents={{ ...customComponents }} userActions={userActionsOnComponent}>
          <ThemeProvider theme={createTheme()}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <GlobalStyles />
                <Header />
                <ScrollToTop />
                <Main />
                <Footer />
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </ClientUIContextProvider>
      </StencilTenant>
      <DialogVideo
        open={videoDialog}
        video={videoInfo}
        close={() => {
          setVideoInfo(null);
          openVideoDialog(false);
        }}
      />
    </div>
  );
}

export default App;
